<template>
  <v-card :loading="loading">
    <v-card-title class="align-start">
      <span>الواردات</span>
      <v-spacer></v-spacer>

      <v-btn icon small class="mt-n2 me-n3">
        <v-icon size="22">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <!-- <v-col cols="12" sm="6">
          <vue-apex-charts id="chart-sales-overview" :options="chartOptions" :series="chartData"
            class="h-full d-flex align-center"></vue-apex-charts>
        </v-col> -->

        <v-col cols="12" sm="12" class="d-flex flex-column justify-center" style="border-left: 5px solid #ddd">
          <div class="d-flex align-center">
            <v-avatar class="v-avatar-light-bg primary--text" rounded size="40">
              <v-icon size="30" color="primary"> fa-user </v-icon>
            </v-avatar>
            <div class="ms-4 d-flex flex-column">
              <p class="text--primary mb-0 text-base">واردات الطلاب</p>
              <!-- <span class="text--primary font-weight-semibold text-xl">$86,400</span> -->
            </div>
          </div>

          <v-divider class="my-6"></v-divider>

          <table class="sales-overview-stats-table">
            <tr>
              <td>
                <div class="mb-0">
                  <div class="stats-dot primary d-inline-block rounded-circle me-2"></div>
                  <span>المبالغ الكلية</span>
                </div>
                <span class="text-base text--primary font-weight-semibold ms-4">{{
                  numberWithComma(reports.studentData.salaryAmountStudent) + reports.studentData.currencySymbol
                }}</span>
              </td>
              <td>
                <div class="mb-0">
                  <div class="stats-dot primary d-inline-block rounded-circle me-2"></div>
                  <span>المبالغ المستحصلة</span>
                </div>
                <span class="text-base text--primary font-weight-semibold ms-4">{{
                  numberWithComma(reports.studentData.paymentAmountStudent) + reports.studentData.currencySymbol
                }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <div class="mb-0">
                  <div class="stats-dot primary d-inline-block rounded-circle me-2"></div>
                  <span>الخصومات الكلية</span>
                </div>
                <span class="text-base text--primary font-weight-semibold ms-4">{{
                  numberWithComma(reports.studentData.discountAmountStudent) + reports.studentData.currencySymbol
                }}</span>
              </td>
              <td>
                <div class="mb-0">
                  <div class="stats-dot secondary d-inline-block rounded-circle me-2"></div>
                  <span>الديون المتبقية</span>
                </div>
                <span class="text-base text--primary font-weight-semibold ms-4">{{
                  numberWithComma(
                    reports.studentData.salaryAmountStudent -
                      reports.studentData.paymentAmountStudent -
                      reports.studentData.discountAmountStudent,
                  ) + reports.studentData.currencySymbol
                }}</span>
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import numberWithComma from '@/constant/number'
import { getVuetify } from '@core/utils'
import { mdiCurrencyUsd, mdiDotsVertical } from '@mdi/js'

export default {
  props: {
    reports: {
      type: Object,
      default: () => ({
        studentData: {
          salaryAmountStudent: '0',
          paymentAmountStudent: '0',
          discountAmountStudent: '0',
          currencySymbol: ' IQD',
        },
        transferredStudentData: {
          salaryAmountStudent: '0',
          paymentAmountStudent: '0',
          discountAmountStudent: '0',
          currencySymbol: ' IQD',
        },
        otherData: {
          paymentAmount: '0',
          salaryAmount: '0',
          discountAmount: '0',
        },
        allData: {
          paymentAmount: '0',
          salaryAmount: '0',
          discountAmount: '0',
          salaryDiscountAmount: '0',
        },
      }),
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VueApexCharts: () => import('vue-apexcharts'),
  },

  setup () {
    const $vuetify = getVuetify()
    // const study_year = JSON.parse(localStorage.getItem('study_year'))
    // const reports = ref({
    //   studentData: {
    //     salaryAmountStudent: 0,
    //     paymentAmountStudent: 0,
    //     discountAmountStudent: 0,
    //     currency: "IQD",
    //   },
    //   otherData: {
    //     paymentAmount: 0,
    //     salaryAmount: 0,
    //     discountAmount: 0
    //   },
    //   allData: {
    //     paymentAmount: 0,
    //     salaryAmount: 0,
    //     discountAmount: 0,
    //     salaryDiscountAmount: 0
    //   },
    // });
    // const reportsData = ref({
    //   studentData: {
    //     salaryAmountStudent: "0",
    //     paymentAmountStudent: "0",
    //     discountAmountStudent: "0",
    //     currencySymbol: " IQD",
    //   },
    //   otherData: {
    //     paymentAmount: "0",
    //     salaryAmount: "0",
    //     discountAmount: "0"
    //   },
    //   allData: {
    //     paymentAmount: "0",
    //     salaryAmount: "0",
    //     discountAmount: "0",
    //     salaryDiscountAmount: "0"
    //   },
    // });

    // onMounted(async () => {
    //   const res = await api.getReports(study_year);
    //   reportsData.value = res.data.results
    // });

    // const chartOptions = {
    //   labels: ['Apparel', 'Electronics', 'FMCG', 'Other Sales', 'Alt'],
    //   colors: [
    //     $vuetify.theme.currentTheme.primary,
    //     addAlpha($vuetify.theme.currentTheme.primary, 0.7),
    //     addAlpha($vuetify.theme.currentTheme.primary, 0.3),
    //     addAlpha($vuetify.theme.currentTheme.secondary, 0.1),
    //   ],
    //   chart: {
    //     type: 'donut',
    //     sparkline: {
    //       enabled: true,
    //     },
    //   },
    //   plotOptions: {
    //     pie: {
    //       donut: {
    //         size: '70%',
    //         labels: {
    //           show: true,
    //           name: {
    //             fontSize: '14px',
    //             offsetY: 25,
    //           },
    //           value: {
    //             fontSize: '2.125rem',
    //             fontWeight: 600,

    //             offsetY: -15,
    //             formatter(value) {
    //               return `${value}k`
    //             },
    //           },
    //           total: {
    //             show: true,
    //             label: 'Weekly Visits',
    //             color: 'rgba(94, 86, 105, 0.68)',
    //             formatter(value) {
    //               return `${value.globals.seriesTotals.reduce((total, num) => total + num)}k`
    //             },
    //           },
    //         },
    //       },
    //     },
    //   },
    // }

    // console.log(chartOptions);

    // const chartData = [12, 24.5, 15, 50, 3]

    return {
      // chartOptions,
      // chartData,
      // reportsData,
      numberWithComma,
      icons: {
        mdiDotsVertical,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>

<style lang="scss">
#chart-sales-overview {
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
      }

      &.apexcharts-datalabel-label {
        font-size: 1rem;
      }
    }
  }
}

.sales-overview-stats-table {
  width: 100%;

  td {
    padding-bottom: 1rem;
  }

  .stats-dot {
    padding: 0.33rem;
  }

  // Set opacity of dots
  tr {
    &:nth-of-type(1) {
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.7;
        }
      }
    }

    &:nth-of-type(2) {
      td:nth-of-type(1) {
        .stats-dot {
          opacity: 0.5;
        }
      }

      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.15;
        }
      }
    }
  }
}
</style>
